import React, { useState } from "react";
import "./resume.scss"

export default function Resume(){

    const [currentSlide,setCurrentSlide] = useState(0);

    const data = [
        {
            id: "1",
            icon: "resumeAssets/devakLogo.jpeg",
            title: "DEÜ 75.Yıl İlkokulu-Ortaokulu",
            desc: "I was in this school between ages 6-14. In this primary/secondary school, I developed both socially and intellectually. My interest for mathematics, coding, robotics started to show itself around this period.",
            img: "resumeAssets/devak.jpg",
        },
        {
            id: "2",
            icon: "resumeAssets/takevLogo.jpeg",
            title: "TAKEV Fen Lisesi",
            desc: "Between ages 14-18, I was in this high school. Through the years, I have done research projects, participated/conducted social/scientific events & contests. After first three years, I was awarded with C1 language certificate in German. I graduated this school as the valedictorian.",
            img: "resumeAssets/takev.JPG"
        },
        {
            id: "3",
            icon: "resumeAssets/kocLogo.png",
            title: "Koç University",
            desc: "I started this school in 2020 as the student who got the highest score in the university enterance exam (YKS). I will graduate from here in 2024. My major is computer science & engineering, but I will be doing double major with business next semester.",
            img: "resumeAssets/koc.png",
        },
    ]

    const handleClick= (way) => {
        way === "left" ? setCurrentSlide(currentSlide > 0 ? currentSlide-1 : 2) : 
        setCurrentSlide(currentSlide<data.length-1 ? currentSlide+1 : 0);
    }

    return(
        <div className="resume" id="resume">
            <div className="slider" style={{transform:`translateX(-${currentSlide*100}vw)`}}>
                {data.map((d)=>(
                    <div className="container">
                        <div className="item">
                            <div className="left">
                                <div className="leftContainer">
                                    <div className="imgContainer">
                                        <img src={d.icon} alt=""></img>
                                    </div>
                                    <h2>{d.title}</h2>
                                    <p>
                                        {d.desc}
                                    </p>
                                    <span>Projects</span>
                                </div>
                            </div>
                            <div className="right">
                                <img src={d.img} alt="" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <img src="assets/downArrow.png" className="arrow left" alt="" onClick={()=>handleClick("left")}/>
            <img src="assets/downArrow.png" className="arrow right" alt="" onClick={()=>handleClick("right")}/>

        </div>

    )

}