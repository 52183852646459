export const featuredPortfolio = [
    {
        id: 1,
        title: "Mobile Game Design",
        img: "portfolioAssets/featured/app2.png",
    },
    {
        id: 2,
        title: "Photo Edit",
        img: "portfolioAssets/featured/ironSaber.JPG",
    },
    {
        id: 3,
        title: "Mobile Game Design",
        img: "portfolioAssets/featured/app1.png",
    },
    {
        id: 4,
        title: "Breakdance",
        img: "portfolioAssets/featured/breakDance.PNG",
    },
    {
        id: 5,
        title: "YouTube Channel",
        img: "portfolioAssets/featured/youtube1.png",
    },
    {
        id: 6,
        title: "Website Design",
        img: "portfolioAssets/featured/myPortfolioWebsite.png",
    },
]

export const webPortfolio = [
    {
        id: 1,
        title: "Website Design",
        img: "portfolioAssets/web/myPortfolioWebsite.png",
    },
]

export const mobilePortfolio = [
    {
        id: 1,
        title: "Mobile Game Design",
        img: "portfolioAssets/mobile/app1.png",
    },
    {
        id: 2,
        title: "Mobile Game Design",
        img: "portfolioAssets/mobile/app2.png",
    },
    {
        id: 3,
        title: "Mobile Game Design",
        img: "portfolioAssets/mobile/app3.png",
    },
    {
        id: 4,
        title: "Mobile Game Design",
        img: "portfolioAssets/mobile/comp132game.png",
    },
]

export const mediaPortfolio = [
    {
        id: 1,
        title: "YouTube Page",
        img: "portfolioAssets/media/youtube1.png",
    },
    {
        id: 2,
        title: "YouTube Videos",
        img: "portfolioAssets/media/youtube2.png",
    },
    {
        id: 3,
        title: "Personal Account",
        img: "portfolioAssets/media/ecInsta.png",
    },
    {
        id: 4,
        title: "Business Account",
        img: "portfolioAssets/media/jocInsta.png",
    },
]

export const editPortfolio = [
    {
        id: 1,
        title: "Profile Photo Design",
        img: "portfolioAssets/edit/youtubePP.png",
    },
    {
        id: 2,
        title: "Photo Edit",
        img: "portfolioAssets/edit/ironSaber.JPG",
    },
    {
        id: 3,
        title: "Photo Edit",
        img: "portfolioAssets/edit/thorSaber.JPG",
    },
    {
        id: 4,
        title: "Video Edit",
        img: "portfolioAssets/edit/tu9.png",
    },
    {
        id: 5,
        title: "Personal Portrait",
        img: "portfolioAssets/edit/Eren Ceylan pp.JPG",
    },
    {
        id: 6,
        title: "Neon Art",
        img: "portfolioAssets/edit/neonGlasses.png",
    },
]