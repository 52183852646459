import React from "react";
import "./skills.scss"

export default function Skills(){

    return(
        <div className="skills" id="skills">
            <h1>Skills</h1>
            <div className="container">

                <div className="c">
                    <div className="left">
                        <h2>C</h2>
                    </div>
                    <div className="right">
                        <div className="level"></div>
                    </div>
                </div>

                <div className="python">
                    <div className="left">
                        <h2>Python</h2>
                    </div>
                    <div className="right">
                    <div className="level"></div>
                    </div>
                </div>

                <div className="java">
                    <div className="left">
                        <h2>Java</h2>
                    </div>
                    <div className="right">
                    <div className="level"></div>
                    </div>
                </div>

                <div className="web">
                    <div className="left">
                        <h2>HTML,CSS,React</h2>
                    </div>
                    <div className="right">
                    <div className="level"></div>
                    </div>
                </div>

                <div className="edit">
                    <div className="left">
                        <h2>Photo & Video Editting</h2>
                    </div>
                    <div className="right">
                    <div className="level"></div>
                    </div>
                </div>

                <div className="full">
                    <div className="left">
                        <h2>Out Of</h2>
                    </div>
                    <div className="right">
                        <div className="level"></div>
                    </div>
                </div>
            </div>
        </div>

    )

}