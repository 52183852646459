import {useEffect, useState} from "react";
import PortfolioList from "../portfolioList/PortfolioList";
import "./portfolio.scss"
import { featuredPortfolio, webPortfolio, mobilePortfolio, mediaPortfolio, editPortfolio } from "../../data" ;

export default function Portfolio(){

    const [selected, setSelected] = useState("featured");
    const [data, setData] = useState([]);

    const list = [
        {
            id: "featured",
            title: "Featured",
        },
        {
            id: "mobile",
            title: "Mobile App",
        },
        {
            id: "web",
            title: "Web App",
        },
        {
            id: "media",
            title: "Social Media",
        },
        {
            id: "edit",
            title: "Photo Edit",
        },
    ];

    useEffect(()=>{

        switch(selected){
            case "featured":
                setData(featuredPortfolio);
                break;
            case "web":
                setData(webPortfolio);
                break;
            case "mobile":
                setData(mobilePortfolio);
                break;
            case "media":
                setData(mediaPortfolio);
                break;
            case "edit":
                setData(editPortfolio);
                break;
            default:
                setData(featuredPortfolio)
        }

    },[selected])

    return(
        <div className="portfolio" id="portfolio">
            <h1>Portfolio</h1>
            <ul>
                {list.map(item=>(
                    <PortfolioList 
                        title={item.title} 
                        active={selected === item.id} 
                        setSelected={setSelected}
                        id={item.id}
                    />
                ))}
            </ul>
            <div className="container">
                {data.map((d)=>(
                    <div className="item">
                        <img 
                            src={d.img} 
                            alt="">
                        </img>
                        <h3>{d.title}</h3>
                    </div>
                ))}
            </div>
        </div>
    )

}