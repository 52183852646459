import React, { useEffect, useRef } from "react";
import "./home.scss";
import { init } from 'ityped';

export default function Home(){

    const textRef = useRef();

    useEffect(()=>{
        init(textRef.current, { 
            showCursor: true, 
            backDelay: 1500,
            backSpeed: 60,
            strings: ["Science & Engineering Student","Enthusiast", "Application Developer"] })
    },[])

    return(
        <div className="home" id="home">
            <div className="left">
                <div className="imgContainer">
                    <img src="assets/erenDark.PNG" alt=""></img>
                </div>
            </div>

            <div className="right">
                <div className="wrapper">
                    <h2>Hi there, I'm</h2>
                    <h1>Eren Ceylan</h1>
                    <h3>Computer <span ref={textRef}></span></h3>
                </div>

                <a href="#portfolio">
                    <img src="assets/downArrow.png" alt=""></img>
                </a>
            </div>
        </div>

    )

}